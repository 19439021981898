import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export const Button: FC<RouteComponentProps<any>> = () => {

  return (
    <div>
       <h1>Generar Factura</h1>
      <button>Generar</button>
    </div>
  );
}