import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button } from './components';
import nexo from "./nexoClient"; 
import { connect, iAmReady } from "@tiendanube/nexo";

function App() {
  const [isConnect, setIsConnect] = useState(false);
  
  useEffect(() => {
    connect(nexo).then(() => {
      setIsConnect(true);
      iAmReady(nexo);
    });
  }, []);

  if (!isConnect) return <h1>Conectando...</h1>;

  return (
    <Switch>
      <Route exact path="/" component={Button} />
    </Switch>
  );
}

export default App;